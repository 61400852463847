<template>
	<div class="px-3">
		<v-btn text dark @click="$router.push('/')">
			<v-icon>mdi-arrow-left</v-icon>Volver al incio
		</v-btn>
		<div class="d-flex align-center ml-6">
			<h2 class="white--text">Promociones</h2>
		</div>
		<div>
			<v-row>
				<v-col md="12" lg="6">
					<v-card @click="siguenos = true">
						<v-img src="https://apitr.xyz/dominio/promo30_2.jpg"></v-img>
						<v-card-title>30% PARTIDA!!”</v-card-title>
						<v-card-subtitle>Click para ver mas detalles</v-card-subtitle>
					</v-card>
				</v-col>
				<v-col md="12" lg="6">
					<v-card @click="partida = true">
						<v-img src="https://apitr.xyz/dominio/mas1.jpg"></v-img>
						<v-card-title>TRACKANDRACES SIEMPRE PAGA MAS!!</v-card-title>
						<v-card-subtitle>Click para ver mas detalles</v-card-subtitle>
					</v-card>
				</v-col>
				<!--  <v-col md="12" lg="6">
        <v-card @click="emocion = true">
          <v-img src="https://apitr.xyz/dominio/postbono.jpg"></v-img>
          <v-card-title>100% EMOCION!!</v-card-title>
          <v-card-subtitle>Click para ver mas detalles</v-card-subtitle>
        </v-card> 

				</v-col>-->
			</v-row>
			<br />
			<br />
			<br />
			<br />
			<br />
		</div>

		<v-dialog v-model="siguenos" scrollable max-width="920">
			<v-card>
				<v-card-title class="headline">30% PARTIDA!!”</v-card-title>

				<v-card-text>
					<h3>¿En qué consiste la promoción?</h3>
					<p>Al realizar tu primer depósito de dinero real en cualquiera de tus carteras, TrackandRaces te otorgará un 30% extra sobre tu deposito, como saldo de bono.</p>
					<h3>¿Cómo se obtiene?</h3>
					<p>
						Realiza un primer depósito en la moneda de tu preferencia a través de las opciones disponible de la página, Notifica tu deposito a través del modulo de “Recarga de Saldo” seleccionando la cartera del tipo de moneda en la cual hiciste el depósito. Una vez verificado tu deposito el sistema acreditara tu saldo como crédito redimible y un 30% del valor de tu deposito como bono.
						<br />
						<br />Vigencia: 14 de Diciembre, hasta el 27 de Diciembre del año en curso.
						<br />
						<br />
					</p>
					<p>Si tienes alguna duda de cómo activar tu bono comunícate con nosotros:</p>
					<ul>
						<li>Escribirnos a Soporte@trackandraces.com</li>
						<li>Mediante el chat de la página.</li>
						<li>Mediante nuestro contacto de WhatsApp +51-933-292-975.</li>
					</ul>
					<br />
					<h3>¿Cómo se liberan ganancias?</h3>
					<p>Para liberar el bono se deben cumplir con requisitos de apuesta de hasta 3 Rollover del monto inicial de créditos redimibles depositado y bono (combinados) durante el tiempo establecido de la promoción.</p>
					<!-- <table style="border: 1px solid #ddd">
						<thead>
							<tr>
								<th style="border: 1px solid #ddd">Monto bono</th>
								<th style="border: 1px solid #ddd">Rollover</th>
								<th style="border: 1px solid #ddd">Monto x rollover</th>
								<th style="border: 1px solid #ddd">Total maximo a covertir</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style="border: 1px solid #ddd" class="text-center">200</td>
								<td style="border: 1px solid #ddd" class="text-center">20</td>
								<td style="border: 1px solid #ddd" class="text-center">4000</td>
								<td style="border: 1px solid #ddd" class="text-center">200</td>
							</tr>
						</tbody>
					</table>-->
					<br />
					<h2>TERMINOS Y CONDICIONES:</h2>

					<p>
						El monto máximo que se puede convertir a créditos redimibles como ganancias netas del
						<b>bono será igual al 100% del monto total depositados en créditos redimibles.</b>
					</p>
					<ul>
						<li>El bono es exclusivo para nuevos usuarios de Trackandraces que se registren en la plataforma, y que sean mayores de 18 años.</li>
						<li>
							Se otorgará un solo bono
							<b>“30% PARTIDA”</b> por deposito.
							<b>Cuentas duplicadas no califican para la promoción.</b>
						</li>
						<li>No se puede combinar en la cuenta del usuario dos bonos del mismo tipo.</li>
						<!-- <li>No se podrá combinar con ninguna otra promoción de bonos.</li> -->
						<li>El bono estará vigente durante el periodo establecido en la promoción y a partir de que ha sido otorgado, transcurrido ese tiempo si NO se cumplen con las condiciones de apuestas de la promoción el bono y las ganancias generadas serán canceladas.</li>
						<li>
							El importe máximo que se puede retirar de ganancias netas del bono sera del
							<b>100% del monto total depositados en créditos redimibles</b>. Las ganancias excedentes, serán canceladas o borradas del sistema.
						</li>
						<li>Esta promoción está limitada a una cuenta por cliente, es decir, una por familia, domicilio, dirección IP, dirección de correo electrónico, número telefónico, número de cuenta de pago (p. ej., tarjeta de crédito o débito / cuenta de banco.</li>
						<li>Track and Races actuará con determinación para evitar que se deforme la finalidad del bono, que es principalmente la de probar los juegos y disfrutar del entretenimiento.</li>
						<li>Si se solicita un retiro antes de cumplir con los requisitos de apuesta, el retiro será cancelado automáticamente Trackandraces se reserva el derecho de quitar el bono y la ganacia desde la cuenta de juego</li>
						<li>Los bonos no son transferibles a otros usuarios.</li>
						<li>Track and Races se reserva el derecho de modificar/cancelar esta promoción en cualquier momento. Se aplican Términos y Condiciones Generales de TrackandRaces y Términos y condiciones generales de Bonos</li>
						<li>
							Track and Races se reserva el derecho de modificar los Términos y Condiciones de esta oferta en cualquier momento y es responsabilidad del usuario comprobar de forma periódica los cambios y las actualizaciones que se realicen en dichos Términos y Condiciones. En caso de cambios que incluyan los términos
							de requisitos de apuesta, TrackandRaces informará a los usuarios
						</li>
						<li>En caso de cambios que incluyan los términos de requisitos de apuesta TrackandRaces informará a sus usuarios.</li>
					</ul>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="red darken-1" text @click="siguenos = false">cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="partida" scrollable max-width="920">
			<v-card>
				<v-card-title class="headline">TRACKANDRACES SIEMPRE PAGA MAS!!</v-card-title>

				<v-card-text>
					<h3>¿En qué consiste la promoción?</h3>
					<p>Al realizar cualquier apuesta tipo “GANADOR” en cualquiera de los eventos o carreras seleccionada a participar en la promoción, TrackandRaces sumara un monto en unidad extra sobre el dividendo oficial confirmado por el hipódromo.</p>
					<h3>¿Cómo se obtiene?</h3>
					<p>
						Solo debes apostar jugadas tipo “GANADOR” al ejemplar de tu preferencia en los eventos seleccionados por Trackandraces.com, si tu ejemplar llega de primero, Trackandraces automáticamente sumara el monto extra en unidades promocionado para el evento o carrera al dividendo oficial que confirme el hipódromo.
						Si tu ticket es ganador veras reflejado el monto total ganado ya incluyendo el monto extra en unidad otorgado al ejemplar
						<br />
						<br />Trackandraces, notificara través de sus redes sociales / correo electrónico / SMS a los telf. registrados los eventos o carreras que participaran en la promoción, por lo que deberás estar atento a nuestras redes o tu correo electrónico para ver cuales eventos o carreras participan
						<br />
						<br />Vigencia: 30 de NOVIEMBRE, hasta el 13 de DICIEMBRE del año en curso.
					</p>
					<p>Si tienes alguna duda de cómo activar tu bono comunícate con nosotros:</p>
					<ul>
						<li>Escribirnos a Soporte@trackandraces.com</li>
						<li>Mediante el chat de la página.</li>
						<li>Mediante nuestro contacto de WhatsApp +51-933-292-975</li>
					</ul>
					<br />
					<h3>¿Cómo se liberan ganancias?</h3>
					<p>
						Solo debes apostar jugadas tipo “GANADOR” al ejemplar de tu preferencia en los eventos seleccionados por Trackandraces.com, si tu ejemplar llega de primero, Trackandraces automáticamente sumara el monto extra en unidades promocionado para el evento o carrera al dividendo oficial que confirme el hipódromo.
						Si tu ticket es ganador veras reflejado el monto total ganado ya incluyendo el monto extra en unidad otorgado al ejemplar
					</p>
					<br />
					<h2>TERMINOS Y CONDICIONES:</h2>
					<br />
					<ul>
						<li>Promoción valida solo para hipódromos de clasificación tipo “A”.</li>
						<li>El bono es exclusivo para nuevos usuarios de Trackandraces que estén registrados en nuestra plataforma, y que sean mayores de 18 años.</li>
						<li>
							Se otorgará un solo pago
							<b>“TRACKANDRACES SIEMPRE DA MAS”</b> por cada ticket apostado en modalidad
							<b>“GANADOR”. Cuentas de usuarios duplicados no califican para la promoción.</b>
						</li>
						<li>
							El bono
							<b>“TRACKANDRACES SIEMPRE DA MAS”</b> estará vigente durante el periodo establecido en la promoción
						</li>
						<li>El pago máximo se regirá bajo las reglas establecidas según la clasificación del hipódromo o evento.</li>
						<li>Esta promoción está limitada a una cuenta por cliente, es decir, una por familia, domicilio, dirección IP, dirección de correo electrónico, número telefónico, número de cuenta de pago (p. ej., tarjeta de crédito o débito / cuenta de banco.</li>
						<li>Track and Races actuará con determinación para evitar que se deforme la finalidad del bono, que es principalmente la de probar los juegos y disfrutar del entretenimiento.</li>
						<li>Si se solicita un retiro antes de cumplir con los requisitos de apuesta, el retiro será cancelado automáticamente Trackandraces se reserva el derecho de quitar el bono y la ganacia desde la cuenta de juego</li>
						<li>Los bonos no son transferibles a otros usuarios.</li>
						<li>Track and Races se reserva el derecho de modificar/cancelar esta promoción en cualquier momento. Se aplican Términos y Condiciones Generales de TrackandRaces y Términos y condiciones generales de Bonos</li>
						<li>
							Track and Races se reserva el derecho de modificar los Términos y Condiciones de esta oferta en cualquier momento y es responsabilidad del usuario comprobar de forma periódica los cambios y las actualizaciones que se realicen en dichos Términos y Condiciones. En caso de cambios que incluyan los términos
							de requisitos de apuesta, TrackandRaces informará a los usuarios
						</li>
						<li>En caso de cambios que incluyan los términos de requisitos de apuesta Track and Races informará a sus usuarios.</li>
					</ul>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="red darken-1" text @click="partida = false">cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="emocion" scrollable max-width="920">
			<v-card>
				<v-card-title class="headline">100% EMOCION!!”</v-card-title>

				<v-card-text>
					<h3>¿En qué consiste la promoción?</h3>
					<p>Al realizar tu cualquier depósito en cualquiera de tus carteras, TrackandRaces te otorgará un 100% extra sobre tu PRIMER deposito, como saldo de bono.</p>
					<h3>¿Cómo se obtiene?</h3>
					<p>
						Realiza un depósito en la moneda de tu preferencia a través de las opciones disponible de la página, Notifica tu deposito a través del módulo de “Recarga de Saldo” seleccionando la cartera del tipo de moneda en la cual hiciste el depósito. Una vez verificado tu deposito el sistema acreditara tu saldo como
						crédito redimible y un 100% del valor de tu deposito como bono.
						<br />
						<br />Vigencia: 5 Dias.
					</p>
					<p>Si tienes alguna duda de cómo activar tu bono comunícate con nosotros:</p>
					<ul>
						<li>Escribirnos a Soporte@trackandraces.com</li>
						<li>Mediante el chat de la página.</li>
						<li>Mediante nuestro contacto de WhatsApp +51-933-292-975</li>
					</ul>
					<br />
					<h3>¿Cómo se liberan ganancias?</h3>
					<p>Para liberar el bono se deben cumplir con requisitos de apuesta de hasta 5 Rollover del monto inicial de créditos redimibles depositado y bono (combinados) durante el tiempo establecido de la promoción.</p>
					<br />
					<h2>TERMINOS Y CONDICIONES:</h2>
					<p>
						El monto máximo que se puede convertir a créditos redimibles de la ganancia neta de saldo
						<b>bono tendrá un límite o no podrá ser mayor al total depositados en créditos redimibles.</b>
					</p>
					<br />
					<ul>
						<li>El bono es exclusivo para nuevos usuarios de Trackandraces que estén registrados en nuestra plataforma, y que sean mayores de 18 años.</li>
						<li>
							Se otorgará un solo bono
							<b>“100% EMOCION”</b> por deposito.
							<b>Cuentas de usuarios duplicados no califican para la promoción.</b>
						</li>
						<li>No se puede combinar en la cuenta del usuario dos bonos del mismo tipo.</li>
						<li>
							No se podrá combinar saldos de bono
							<b>“100% EMOCION”</b> con los créditos de bono “Siguenos 200” de hacer un depósitos de saldo redimible y participar en la promoción “100 EMOCION” antes de la fecha fin de “Síguenos 200” los créditos de esta última serán automáticamente cancelados.
						</li>
						<li>El bono estará vigente durante el periodo establecido en la promoción y a partir de que ha sido otorgado, transcurrido ese tiempo si NO se cumplen con las condiciones de apuestas de la promoción el bono y las ganancias generadas serán canceladas.</li>
						<li>
							El importe máximo que se puede convertir de ganancias netas del bono será del
							<b>100% del monto total depositados en créditos redimibles.</b> Las ganancias excedentes, serán canceladas o borradas del sistema.
						</li>
						<li>Esta promoción está limitada a una cuenta por cliente, es decir, una por familia, domicilio, dirección IP, dirección de correo electrónico, número telefónico, número de cuenta de pago (p. ej., tarjeta de crédito o débito / cuenta de banco.</li>
						<li>TrackandRaces actuará con determinación para evitar que se deforme la finalidad del bono, que es principalmente la de probar los juegos y disfrutar del entretenimiento.</li>
						<li>Si se solicita un retiro antes de cumplir con los requisitos de apuesta, el retiro será cancelado automáticamente Trackandraces se reserva el derecho de quitar el bono y la ganancia desde la cuenta de juego.</li>
						<li>Los bonos no son transferibles a otros usuarios.</li>
						<li>TrackandRaces se reserva el derecho de modificar/cancelar esta promoción en cualquier momento. Se aplican Términos y Condiciones Generales de TrackandRaces y Términos y condiciones generales de Bonos.</li>
						<li>
							TrackandRaces se reserva el derecho de modificar los Términos y Condiciones de esta oferta en cualquier momento y es responsabilidad del usuario comprobar de forma periódica los cambios y las actualizaciones que se realicen en dichos Términos y Condiciones. En caso de cambios que incluyan los términos de
							requisitos de apuesta, TrackandRaces informará a los usuarios.
						</li>
						<li>En caso de cambios que incluyan los términos de requisitos de apuesta TrackandRaces informará a sus usuarios.</li>
					</ul>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="red darken-1" text @click="emocion = false">cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			siguenos: false,
			partida: false,
			emocion: false
		};
	}
};
</script>

<style></style>
